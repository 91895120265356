import { Box } from "@mittwald/flow-components/dist/components/Box";
import { CheckBox } from "@mittwald/flow-components/dist/components/CheckBox";
import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import { Form, useForm } from "@mittwald/flow-components/dist/components/Form";
import DefaultModal from "@mittwald/flow-components/dist/components/Modal/DefaultModal";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { iconUser } from "@mittwald/flow-icons/dist/user";
import React, { FC } from "react";
import { MySqlDatabase } from "../../../../../model/database/MySqlDatabase";
import { NewDatabaseUserInputs } from "../../../../../model/database/MySqlUser";
import { DatabaseUserPasswordField } from "../../components/DatabaseUserPasswordField";

interface Props {
  database: MySqlDatabase;
}

export const CreateDatabaseUserWizard: FC<Props> = (props) => {
  const visibility = useVisibilityController();

  const form = useForm<NewDatabaseUserInputs>({
    translationKey: "createDatabaseUser",
    showSuccessFeedback: true,
    defaultValues: {
      fullAccess: true,
    },
    onSubmit: async (values) => {
      await props.database.createUser(values);
    },
  });

  return (
    <DefaultModal
      description="description"
      headline="createDatabaseUser"
      headlineIcon={iconUser}
      primary={{ action: [{ form }, visibility.hide], ok: true }}
      visibility={visibility}
    >
      <Form controller={form}>
        <Section.Layout>
          <Section.Item>
            <ColumnLayout medium={[1, 1]}>
              <TextField
                autoFocus
                label="userDescription"
                name="description"
                rules={{ required: true }}
              />
              <DatabaseUserPasswordField />
            </ColumnLayout>
          </Section.Item>
          <Section.Item headline="permissions">
            <Text i18n="description" />
            <Box _flexDirection="row" _itemGap="m">
              <CheckBox name="fullAccess" title="fullDatabaseAccess" />
              <CheckBox name="externalAccess" title="externalDatabaseAccess" />
            </Box>
          </Section.Item>
        </Section.Layout>
      </Form>
    </DefaultModal>
  );
};

export default CreateDatabaseUserWizard;
