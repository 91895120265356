import { showModal } from "@mittwald/flow-components/dist/components/FunctionModalsContainer";
import { FC, useEffect, useRef } from "react";
import { CustomerActionDeniedModal } from "../../pages/app/customer/components/CustomerActionDeniedModal";
import Customer from "../customer/Customer";
import Domain from "../domain/Domain";
import React from "react";
import { ProSpaceProject } from "../project";
import ProjectBase from "../project/ProjectBase";

interface BankruptGuardProps {
  entity: Customer | ProjectBase | Domain;
  onAction: (isBankrupt: boolean) => void;
}

const CustomerBankruptCheck: FC<{
  customer: Customer;
  onAction: (isBankrupt: boolean) => void;
}> = ({ customer, onAction }) => {
  const isBankrupt = customer.useIsBankrupt();

  useEffect(() => {
    if (isBankrupt) {
      showModal("customerBankrupt", <CustomerActionDeniedModal />);
    } else {
      onAction(isBankrupt);
    }
  }, [onAction, isBankrupt]);

  return null;
};

const ProjectBankruptCheck: FC<{
  project: ProjectBase | ProSpaceProject;
  onAction: (isBankrupt: boolean) => void;
}> = ({ project, onAction }) => {
  const customer = project.useCustomer();
  const isBankrupt = customer?.useIsBankrupt() ?? false;
  const hasCalledAction = useRef(false);

  useEffect(() => {
    if (!hasCalledAction.current) {
      hasCalledAction.current = true;

      if (isBankrupt && customer) {
        showModal("customerBankrupt", <CustomerActionDeniedModal />);
      } else {
        onAction(isBankrupt);
      }
    }

    return () => {
      hasCalledAction.current = false;
    };
  }, [customer, isBankrupt]);

  return null;
};

const DomainBankruptCheck: FC<{
  domain: Domain;
  onAction: (isBankrupt: boolean) => void;
}> = ({ domain, onAction }) => {
  const project = domain.useProject();
  const customer = project.useCustomer();
  const isBankrupt = customer?.useIsBankrupt() ?? false;

  useEffect(() => {
    if (isBankrupt && customer) {
      showModal("customerBankrupt", <CustomerActionDeniedModal />);
    } else {
      onAction(isBankrupt);
    }
  }, [customer, onAction, isBankrupt]);

  return null;
};

const BankruptGuardCheck: FC<BankruptGuardProps> = ({ entity, onAction }) => {
  if (entity instanceof Customer) {
    return <CustomerBankruptCheck customer={entity} onAction={onAction} />;
  }
  if (entity instanceof Domain) {
    return <DomainBankruptCheck domain={entity} onAction={onAction} />;
  }
  return <ProjectBankruptCheck project={entity} onAction={onAction} />;
};

export const bankruptcyCheckedAction = (
  entity: Customer | ProjectBase | ProSpaceProject | Domain,
  action: () => void,
): (() => void) => {
  let isCheckCompleted = false;

  return () => {
    if (isCheckCompleted) {
      return;
    }

    showModal(
      "customerActionCheck",
      <BankruptGuardCheck
        entity={entity}
        onAction={(isBankrupt) => {
          if (isCheckCompleted) return;
          isCheckCompleted = true;

          if (!isBankrupt) {
            action();
          }
        }}
      />,
    );
  };
};
