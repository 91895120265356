import { IconLookup } from "@mittwald/flow-components/dist/components/Icon";
import { SelectBoxOptions } from "@mittwald/flow-components/dist/components/SelectBox";
import { iconApp } from "@mittwald/flow-icons/dist/app";
import { iconContract } from "@mittwald/flow-icons/dist/contract";
import { iconCustomer } from "@mittwald/flow-icons/dist/customer";
import { iconDatabase } from "@mittwald/flow-icons/dist/database";
import { iconDomain } from "@mittwald/flow-icons/dist/domain";
import { iconEmail } from "@mittwald/flow-icons/dist/email";
import { iconInfoCircle } from "@mittwald/flow-icons/dist/infoCircle";
import { iconProject } from "@mittwald/flow-icons/dist/project";
import { iconSshUser } from "@mittwald/flow-icons/dist/sshUser";
import { iconUser } from "@mittwald/flow-icons/dist/user";
import ExtensionList from "../../marketplace/ExtensionList";
import { iconExtension } from "@mittwald/flow-icons/dist/extension";

export class MarketplaceUI {
  public static getScopeIcon(scope: string): IconLookup {
    switch (scope.split(":")[0]) {
      case "mail":
        return iconEmail;
      case "user":
        return iconUser;
      case "customer":
        return iconCustomer;
      case "project":
        return iconProject;
      case "ingress":
        return iconDomain;
      case "domain":
        return iconDomain;
      case "app":
        return iconApp;
      case "database":
        return iconDatabase;
      case "contract":
        return iconContract;
      case "sshUser":
        return iconSshUser;
      case "extension":
        return iconExtension;
      default:
        return iconInfoCircle;
    }
  }

  public static getExtensionOptions(
    availableExtensions: ExtensionList,
  ): SelectBoxOptions {
    return availableExtensions.items.map((extension) => ({
      title: { text: extension.data.name },
      value: extension.id,
      logo: iconExtension,
    }));
  }
}

export default MarketplaceUI;
